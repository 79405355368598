import httpUtil from "@/utils/httpUtil";

/**新增专题 */
export const addSpecialInfo =  params => httpUtil.post("/api/assPc/FunctionIPC/addSpecialInfo", params);

/** 修改列表  */
export const updateSpecialInfo =  params => httpUtil.post("/api/assPc/FunctionIPC/updateSpecialInfo", params);

/** 专题列表  */
export const querySpecialInfoPage =  params => httpUtil.post("/api/assPc/FunctionIPC/querySpecialInfoPage", params);

/** 删除列表  */
export const delSpecialInfo =  params => httpUtil.post("/api/assPc/FunctionIPC/delSpecialInfo", params);


